import Axios, { type AxiosError } from 'axios';
import { errorToast } from '@/components/Toasts';

export const levoAxios = Axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_CDN_URL}/v1`,
  headers: {
    'Content-Type': 'application/json',
    'Levo-Workspace': process.env.NEXT_PUBLIC_LEVO_WORKSPACE,
  },
});

export const levoAxiosV2 = Axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_CDN_URL}/v1`,
  headers: {
    'Content-Type': 'application/json',
    'Levo-Workspace': process.env.NEXT_PUBLIC_LEVO_WORKSPACE,
  },
});

export const errorHandler = (err: AxiosError<any>) => {
  if (err.response?.data?.errors) {
    errorToast(err.response.data.errors[0].message);
  } else {
    errorToast('Something went wrong');
  }

  return err;
};

export type LevoResponseSingle<T, K = Record<string, any>> = {
  status: boolean;
  content: {
    data: T;
    meta?: K;
  }
}

export type LevoResponseList<T, K = {
  total: number;
  pages: number;
}> = {
  status: boolean;
  content: {
    data: T[];
    meta: K;
  }
}
