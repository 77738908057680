import React from 'react';
import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

const Logo: React.FC<{ homeLink?: string }> = ({ homeLink = '/' }) => (
  <Box width={{ xs: '160px', md: '200px' }}>
    <Link href={homeLink} aria-label="IVCA Logo">
      <Image
        src="/png/IVCA-Logo.png"
        alt="IVCA Logo"
        width="240"
        height="90"
        style={{ objectFit: 'contain' }}
        priority
      />
    </Link>
  </Box>
);

export default Logo;
