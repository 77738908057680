import React from 'react';
import { Button, Img, StyleProps } from '@chakra-ui/react';
import { FiArrowUpRight } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';
import LinkWrapperV2 from '../eventsCalendar/LinkWrapperV2';

interface ICustomButtonProps {
  text: string;
  colorScheme?: string;
  icon?: boolean;
  leftIconSrc?: string;
  variant?: string;
  link?: string;
  btnWidth?: string;
  externalLink?: boolean;
  btnStyle?: StyleProps;
  iconProps?: IconBaseProps;
  handleButtonClick?: () => void;

}

const CustomButton: React.FunctionComponent<ICustomButtonProps> = ({
  text,
  colorScheme,
  icon,
  leftIconSrc,
  variant = 'solid',
  link,
  btnWidth,
  externalLink = false,
  btnStyle,
  iconProps,
  handleButtonClick,
}) => (

  <>

    {/* <Link href='mailto:' target='blank'>mail</Link> */}


    <LinkWrapperV2 link={link} external={externalLink}>
      <Button
        aria-label={text}
        rightIcon={
          icon ? (
            <FiArrowUpRight
              size="15px"
              color={colorScheme === 'primary' ? 'white' : 'text.green'}
              {...iconProps}
            />
          ) : (
            <div />
          )
        }
        leftIcon={leftIconSrc ? <Img src={leftIconSrc} alt="icon" /> : <div />}
        py="13px"
        pl="24px"
        colorScheme={colorScheme}
        variant={variant}
        color={colorScheme === 'primary' ? 'white' : 'grey'}
        fontSize="14px"
        fontWeight="700"
        lineHeight="21px"
        letterSpacing="0.02em"
        borderWidth="1.5px"
        borderStyle={colorScheme === 'secondary' ? 'solid' : 'none'}
        borderColor="#DADAFB"
        borderRadius="0px"
        h={{
          md : "47px",
        }}
        w={btnWidth}
        {...btnStyle}
        onClick={() => {
          if (handleButtonClick) {
            handleButtonClick();
          }
        }}
      >
        {text}
      </Button>
    </LinkWrapperV2>
  </>
);

export default CustomButton;
